import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuSubgroup = lazy(
  () => import("./menu-subgroup-GN7-OhXI.js").then((module) => ({
    default: module.MenuSubgroup
  }))
);
function MenuSubgroup({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuSubgroup, { ...props });
}
const useMenuSubgroupContent = () => {
  return useMemo(
    () => ({
      menuSubgroup: {
        component: MenuSubgroup
      }
    }),
    []
  );
};
export {
  LazyMenuSubgroup as MenuSubgroup,
  useMenuSubgroupContent
};
